import { template as template_a979751dd50f4628add0d1603ae03715 } from "@ember/template-compiler";
const FKLabel = template_a979751dd50f4628add0d1603ae03715(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
