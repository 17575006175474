import { template as template_c614d2116f8d489e9614d46de344083b } from "@ember/template-compiler";
const SidebarSectionMessage = template_c614d2116f8d489e9614d46de344083b(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
