import { template as template_2901bda53bc14255bd1bf47d100e134b } from "@ember/template-compiler";
const FKControlMenuContainer = template_2901bda53bc14255bd1bf47d100e134b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
